/*import React from "react"*/
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import $ from "jquery" /* Temporary */
import CookieConsent, {
  getCookieConsentValue,
  resetCookieConsentValue,
  Cookies,
} from "react-cookie-consent"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/pro-light-svg-icons"
import { faXmark } from "@fortawesome/pro-light-svg-icons"

import { initGA } from "./gdpr/ga4-utils"
import csClick from "../functions/cs-click"

import HeaderDash from "./header-dash"
import FooterDash from "./footer-dash"
import "../assets/css/main-dash.css"

import HeaderFunctions from "../functions/header-functions"

const LayoutDash = ({ children }) => {
  let headerFunctions = new HeaderFunctions()

  if (typeof document !== `undefined`) {
    window.isMobile = null
    window.menuToggle = 0
    window.cookieToggle = 0

    let url = window.location.pathname

    headerFunctions.dashToggle()
  }

  return (
    <React.Fragment>
      <HeaderDash />
      <div className="dash-ui-body-container">
        <div className="dash-ui-sidebar">
          <div className="dash-sidebar-row">
            <StaticImage
              src="../assets/images/head-logo-basic.png"
              alt=""
              placeholder="none"
              className="dash-sidebar-icon"
            />
            <div className="dash-dropdown-container">
              <h4 id="dash-sidebar-overview">
                <Link to="/">OVERVIEW</Link>
              </h4>
              {/*<h4 className="dropdown-icon"> &gt; </h4>*/}
            </div>
          </div>
          <div className="dash-sidebar-row">
            <StaticImage
              src="../assets/images/head-logo-basic.png"
              alt=""
              placeholder="none"
              className="dash-sidebar-icon"
            />
            <h4>SERVICES</h4>
            <div className="dash-sidebar-row__inner">
              <div className="dash-sidebar-line">
                <div></div>
              </div>
              <div>
                <h4>
                  <Link to="/dashboard/casefile">CASEFILE</Link>
                </h4>
                <h4>
                  <Link to="/dashboard/railgun">RAILGUN</Link>
                </h4>
                <h4>
                  <Link to="/dashboard/gatecrash">GATECRASH</Link>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="dash-ui-main dash-scroll-fade dash-subsc-fade">
          {children}
        </div>
      </div>
    </React.Fragment>
  )
}

export default LayoutDash
