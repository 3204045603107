import React from "react"
import $ from "jquery"
import LayoutDash from "../../components/layout-dash"

const index = () => {
  function uiAnimateLines() {
    /*
    $(document).ready(() => {
      $(".full-width-line").animate({ width: "100%" }, 2400)
    })
    */

    if (typeof document !== `undefined`) {
      $(document).ready(() => {
        $(".full-width-line").animate({ width: "100%" }, 2400)
      })
    }
  }

  return (
    <LayoutDash>
      <div className="dash-ui-main__left">
        <div className="dash-ui-main__left-1">
          <h1 className="dash-body-title">
            Welcome ‘Company’ to Your IA Dashboard.
          </h1>
        </div>
        <div className="dash-ui-main__left-2 dash-subsc-box">
          <div className="dash-subsc-line-overlay"></div>
          <h1 className="dash-body-title">Upgrade Plan</h1>
          <h1 className="dash-body-subtitle dash-no-visibility">Active Subscription</h1>
          <div className="dash-spacing-vertical__title-line">
            <div className="dash-full-width-line"></div>
          </div>
          <div className="dash-subsc-row">
            <p>Alpha:</p>
            <p>See Info</p>
          </div>
          <div className="dash-spacing-vertical__p-line">
            <div className="dash-full-width-line"></div>
          </div>
          <div className="dash-subsc-row">
            <p>Beta:</p>
            <p>See Info</p>
          </div>
          <div className="dash-spacing-vertical__p-line">
            <div className="dash-full-width-line"></div>
          </div>
          <div className="dash-subsc-row">
            <p>Charlie:</p>
            <p>See Info</p>
          </div>
        </div>
      </div>
      <div className="dash-ui-main__right">
        <div className="dash-ui-main__right-1 dash-subsc-box">
          <div className="dash-subsc-line-overlay"></div>
          <h1 className="dash-body-title">Charlie</h1>
          <h1 className="dash-body-subtitle">Active Subscription</h1>
          <div className="dash-spacing-vertical__title-line">
            <div className="dash-full-width-line"></div>
          </div>
          <div className="dash-subsc-row">
            <p>RailGuns Remaining:</p>
            <p>55/200</p>
          </div>
          <div className="dash-spacing-vertical__p-line">
            <div className="dash-full-width-line"></div>
          </div>
          <div className="dash-subsc-row">
            <p>CaseFiles Remaining:</p>
            <p>10/30</p>
          </div>
          <div className="dash-spacing-vertical__p-line">
            <div className="dash-full-width-line"></div>
          </div>
          <div className="dash-subsc-row">
            <p>GateCrashes Remaining:</p>
            <p>0/2</p>
          </div>
        </div>

        <div className="dash-ui-main__right-2 dash-subsc-box">
          <div className="dash-subsc-line-overlay"></div>
          <h1 className="dash-body-title">Quick Acess</h1>
          <h1 className="dash-body-subtitle">Services</h1>
          <div className="dash-spacing-vertical__title-line">
            <div className="dash-full-width-line"></div>
          </div>
          <div className="dash-subsc-row">
            <p>RailGuns:</p>
            <p>See Completed</p>
          </div>
          <div className="dash-spacing-vertical__p-line">
            <div className="dash-full-width-line"></div>
          </div>
          <div className="dash-subsc-row">
            <p>CaseFiles:</p>
            <p>See Completed</p>
          </div>
          <div className="dash-spacing-vertical__p-line">
            <div className="dash-full-width-line"></div>
          </div>
          <div className="dash-subsc-row">
            <p>GateCrashes:</p>
            <p>See Completed</p>
          </div>
        </div>
      </div>

      {/*uiAnimateLines()*/}
    </LayoutDash>
  )
}

export default index
