import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/pro-light-svg-icons"

import HeaderFunctions from "../functions/header-functions"

const HeaderDash = () => {
  return (
    <header className="dash">
      {/*
      <div className="furniture-wrapper">
        <div className="wrapper-inner">
          <div className="page-furniture no-display-mobile">
            <h4 className="">
              <a
                href="https://blog.i-a.cloud/"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                @SUBSCRIBE &gt; IA
              </a>
            </h4>
          </div>
        </div>
      </div>
      */}

      <div className="dash-ui-header-container">
        <div className="dash-ui-header-inner">
          <div className="dash-ui-header__block-1">
            <a href="/" target="_blank">
              <StaticImage
                src="../assets/images/head-logo-basic.png"
                alt=""
                placeholder="none"
                className="dash-vertical-centre dash-padding-right"
              />
            </a>
            {/*
            <Link to="/">
              <StaticImage
                src="../assets/images/head-logo-basic.png"
                alt=""
                placeholder="none"
                className="dash-vertical-centre dash-padding-right"
              />
            </Link>
            */}
            {/*}
            <a
              href="<?php echo site_url('/home'); ?>"
              className="dash-vertical-centre dash-padding-right"
            ></a>
            */}
            <div className="dash-header-button-container dash-vertical-centre dash-padding-right">
              <h4 id="dash-approach-id">
                <div className="dash-dropdown-container">
                  <a
                    href="#"
                    id="dash-header-overview"
                    className="dash-click-scroll"
                  >
                    OVERVIEW
                  </a>
                  <h4 className="dash-dropdown-icon"> &gt; </h4>
                </div>
              </h4>
            </div>
            <div className="dash-header-button-container dash-vertical-centre dash-padding-right">
              <h4 id="dash-approach-id">
                <a
                  href="https://i-a.cloud/#approach-scroll"
                  target="_blank"
                  className="dash-click-scroll"
                >
                  APPROACH
                </a>
              </h4>
            </div>
            <div className="dash-header-button-container dash-vertical-centre dash-padding-right">
              <h4 id="dash-services-id">
                <a
                  href="https://i-a.cloud/#services-scroll"
                  target="_blank"
                  className="dash-temp-colour dash-click-scroll"
                >
                  SERVICES
                </a>
              </h4>
            </div>
            <div className="dash-header-button-container dash-vertical-centre dash-padding-right dash-temp-colour">
              <h4 id="dash-contact-id">
                <a
                  href="https://i-a.cloud/#contact-scroll"
                  target="_blank"
                  className="dash-temp-colour dash-click-scroll"
                >
                  CONTACT
                </a>
              </h4>
            </div>
            <div className="dash-header-button-container dash-vertical-centre dash-temp-colour">
              <h4 id="dash-about-id">
                <a
                  href="https://i-a.cloud/about"
                  target="_blank"
                  className="dash-temp-colour"
                >
                  ABOUT
                </a>
              </h4>
            </div>
          </div>

          <div
            id="desktop-header"
            className="dash-ui-header__block-2 dash-vertical-centre"
          >
            <div className="dash-header-button-container dash-vertical-centre dash-padding-right">
              <h4>
                <a href="https://wiki.i-a.cloud" className="dash-temp-colour">
                  IA &gt; WIKI
                </a>
              </h4>
            </div>

            <div className="dash-disk">
              <div className="dash-disk-strike"></div>

              <a href="#" className="dash-menu-toggle">
                <StaticImage
                  src="../assets/images/account-graphic.png"
                  alt=""
                  placeholder="none"
                  className="dash-menu-icon"
                />
              </a>
            </div>

            {/*
            <div className="disk-overlay">
              <div className="disk-strike"></div>
              <a href="#" className="menu-toggle">
                <i className="menu-icon fal fa-bars"></i>
              </a>
            </div>
            */}
          </div>

          <div
            id="mobile-header"
            className="ui-header__block-2 vertical-centre"
          >
            <div className="header-button-container vertical-centre padding-right">
              <h4>
                <a href="https://wiki.i-a.cloud" className="temp-colour">
                  IA &gt; WIKI
                </a>
              </h4>
            </div>

            <div className="disk">
              <div className="disk-strike"></div>
              <a href="" className="menu-toggle dash-toggle">
                <FontAwesomeIcon icon={faBars} className="menu-icon" />
              </a>
            </div>

            <div className="disk-overlay">
              <div className="disk-strike"></div>
              <a href="" className="menu-toggle dash-toggle">
                <FontAwesomeIcon icon={faBars} className="menu-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-ui-header-mobile">
        <div className="dash-menu-inner">
          <div className="dash-spacing-divider--small dash-no-display-desktop">
            <div className="dash-no-display-desktop dash-divider-white"></div>
          </div>
          <div className="dash-header-button-container dash-vertical-centre dash-padding-right">
            <div className="dash-menu-row">
              <h4 className="dash-current-underline">
                <Link
                  to="/dashboard/casefile"
                  className=""
                >
                  CASEFILE
                </Link>
              </h4>
              <div className="dash-button-arrow-outer">
                <div className="dash-button-arrow-inner">
                  <div className="dash-line-container">
                    <div className="dash-line-1"></div>
                    <div className="dash-line-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dash-spacing-divider--small dash-no-display-desktop">
            <div className="dash-no-display-desktop dash-divider-white"></div>
          </div>
          <div className="dash-header-button-container dash-vertical-centre dash-padding-right">
            <div className="dash-menu-row">
              <h4 className="dash-current-underline">
                <Link
                  to="/dashboard/railgun"
                  className=""
                >
                  RAILGUN
                </Link>
              </h4>
              <div className="dash-button-arrow-outer">
                <div className="dash-button-arrow-inner">
                  <div className="dash-line-container">
                    <div className="dash-line-1"></div>
                    <div className="dash-line-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dash-spacing-divider--small dash-no-display-desktop">
            <div className="dash-no-display-desktop dash-divider-white"></div>
          </div>
          <div className="dash-header-button-container dash-vertical-centre dash-padding-right dash-temp-colour">
            <div className="dash-menu-row">
              <h4>
                <Link
                  to="/dashboard/gatecrash"
                  className=""
                >
                  GATECRASH
                </Link>
              </h4>
              <div className="dash-button-arrow-outer">
                <div className="dash-button-arrow-inner">
                  <div className="dash-line-container">
                    <div className="dash-line-1"></div>
                    <div className="dash-line-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*
          <div className="dash-spacing-divider--small dash-no-display-desktop">
            <div className="dash-no-display-desktop dash-divider-white"></div>
          </div>
          <div className="dash-header-button-container dash-vertical-centre dash-temp-colour">
            <div className="dash-menu-row">
              <h4 className="dash-current-underline">
                <a href="<?php echo site_url('/about'); ?>" className="">
                  ABOUT
                </a>
              </h4>
              <div className="dash-button-arrow-outer">
                <div className="dash-button-arrow-inner">
                  <div className="dash-line-container">
                    <div className="dash-line-1"></div>
                    <div className="dash-line-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}
          <div className="dash-spacing-divider--small dash-no-display-desktop">
            <div className="dash-no-display-desktop dash-divider-white"></div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderDash
